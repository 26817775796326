import { imageAsset, fileVars, resourceData, manualResources } from "queries"

export const query = `{
  entries(section: "resource") {
    id
    title
    uri
    sectionHandle
    typeHandle
    ...on resource_resource_Entry {
      resourceTag {
        id
        slug
        title
      }
      heading
      date
      image {
        ${imageAsset}
      }
      file {
        ${fileVars}
      }
      staffMember {
        id
        slug
        typeHandle
        title
      }
      relatedTag {
        id
        typeHandle
        slug
      }
    }
    ...on resource_simpleResource_Entry {
      date
      image {
        ${imageAsset}
      }
      resourceTag {
        id
        slug
        title
      }
      relatedTag {
        id
        typeHandle
        slug
      }
      staffMember {
        id
        slug
        typeHandle
        title
      }
      resourceLink {
        ...on resourceLink_BlockType {
          externalLink
          fileAsset {
            ${fileVars}
          }
        }
      }
    }
  }
}`

export const featuredResource = `query {
  entries(section: "resourcesIndex") {
    ...on resourcesIndex_resourcesIndex_Entry {
      id
      heading
    }
  }
}`
// featuredResource {
//   ...on featuredResource_featuredResource_BlockType {
//     typeHandle
//     resource {
//       id
//       title
//       uri
//       ...on resource_resource_Entry {
//         resourceTag {
//           title
//         }
//       }
//     }
//     image {
//       ${imageAsset}
//     }
//     copy
//     externalLink
//   }
// }

export const pageQuery = `
query($slug:[String]) {
  entries(section: "resource", slug: $slug) {
    id
    title
    slug
    uri
    typeHandle
    ...on resource_resource_Entry {
      resourceTag {
        id
        slug
        title
      }
      heading
      date
      image {
        ${imageAsset}
      }
      file {
        ${fileVars}
      }
      staffMember {
        id
        slug
        typeHandle
        title
      }
      body
      embed
      relatedTag {
        ...on work_issueArea_Entry {
          id
          typeHandle
          slug
        }
      }
      ${manualResources}
    }
    ...on resource_simpleResource_Entry {
      resourceLink {
        ...on resourceLink_BlockType {
          externalLink
          fileAsset {
            ${fileVars}
          }
        }
      }
    }
  }
}`

export const allResources = `
query($id:QueryArgument,$relatedTo:[Int], $location: [QueryArgument]) {
  entries(section: "resource", id: ["not", $id,20257,20273,20276], relatedTo: $relatedTo, locationTag: $location) {
    ${resourceData}
  }
}`

export const relatedQuery = `
query($id:QueryArgument,$relatedTo:[Int]) {
  entries(section: "resource", id: ["not", $id,20257,20273,20276], relatedTo: $relatedTo) {
    ${resourceData}
  }
}`

export const indexPage = `query {
  entries(section: "resourcesIndex") {
    ...on resourcesIndex_resourcesIndex_Entry {
      id
      heading
    }
  }
}`
