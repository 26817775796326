import React, { useState } from "react"
import Accordion from "blocks/Accordion"
import { useLocation } from "react-router-dom"
import { useFetchStore, useFetchStoreVars } from "Fetch"
import Module from "components/Module"
import NewsModule from "blocks/NewsModule"
import PageTitle from "blocks/PageTitle"
import FilterNav from "blocks/FilterNav"
import SectionHeader from "blocks/SectionHeader"
import ScrollToTop from "blocks/ScrollToTop"
import OutboundLink from "blocks/OutboundLink"
import useBreakpoint from "useBreakpoint"
import {
  allNews,
  press,
  pressPage
} from "./query"

const Press = () => {
  let { search } = useLocation()
  const queryParams = new URLSearchParams(search);

  const filterParams = queryParams.get("filters");
  const issueParams = queryParams.get("filter-issue");
  const locationParams = queryParams.get("location");

  const pageData = useFetchStore(pressPage, "press-index");
  const [filters, setFilters] = useState({
    type: filterParams && filterParams !== "press" ? filterParams.split(",") : [],
    issueArea: issueParams ? issueParams.split(",") : [],
    location: locationParams ? locationParams.split(",") : [],
    section: filterParams === "press" ? "press" : "news",
    date: null
  });


  let title = (pageData && pageData.entries[0].heading) || "News"

  return <section className="press">
    <header className="pad-top pad-bottom max-width">
      <PageTitle heading={title} />
    </header>
    <FilterNav element="newsType" filters={filters} setFilters={setFilters} />
    <ScrollToTop />
    <NewsArticles filters={filters} featured={pageData} />
    {pageData && <ModuleSection modules={pageData.entries[0].modules} />}
  </section>
}

const NewsArticles = ({ filters, featured }) => {
  let relatedTags = filters.type.concat(filters.issueArea)
  let isPress = filters.section === "press"
  let rdxKey = (relatedTags && relatedTags.length) ? `${isPress ? "press" : "news"}-${relatedTags}` : (isPress ? "all-press" : "all-news")
  let isFiltered = filters.date || filters.type.length || filters.issueArea.length || filters.section !== "news" || filters.location.length
  let mobile = useBreakpoint() !== "desktop"
  let featuredArt = featured ? featured.entries[0].featuredContent[0] : null

  const params = !isFiltered
    ? {
      section: ["news", "press"],
      type: ["news", "action"]
    }
    : {
      section: isPress
        ? filters.type.length === 0
          ? ["press"]
          : ["press", "news"]
        : filters.type.length === 0
          ? ["press", "news"]
          : ["news"],
      type: ["news", "action", "press"],
      relatedTo: filters.issueArea,
      location: filters.location && filters.location.length ? filters.location : null
    };

  return <article className={`pad-top pad-bottom max-width ${mobile ? "reorder" : ""} ${isFiltered ? 'grid grid--33' : 'article-grid'}`} style={{ minHeight: "calc(100vh - var(--navHeight))" }}>
    <Accordion
      query={allNews}
      params={params}
      route={rdxKey}
      limit={isFiltered ? 6 : 5}
      filter={{ date: filters.date, type: filters.type, location: filters.location }}
      sort={true}
      buttonClass={isFiltered ? "news-button-alignment center" : "news-button-alignment home-button-alignment"}>
      {(data) => {
        if (!data) return <p>Loading...</p>

        if (data.length === 0) return <p className="no-results">No results</p>

        let first = featuredArt ? featuredArt : data[0]

        if (!isFiltered) {
          return <React.Fragment>
            <NewsModule article={first} featured={true} />
            <PressPanel className="sticky" />
            <ul className="grid grid--50">
              {data.map((article) => {
                return first.id !== article.id ? <li key={article.id}><NewsModule article={article} color="light-gray" key={article.id} /></li> : null
              })}
            </ul>
          </React.Fragment>
        } else {
          return data.map(article => {
            return <NewsModule article={article} color="light-gray" key={article.id} />
            // return isPress ?
            //   <PressLink press={article} key={article.id} />
            //   : <NewsModule article={article} color="light-gray" key={article.id} />
          })
        }
      }}
    </Accordion>
  </article>
}

export const PressPanel = ({ className }) => {
  let mobile = useBreakpoint() === "desktop"
  const inThePress = useFetchStoreVars(press, { limit: 5 }, "press-5")

  return <div className="resort-end">
    {inThePress && <div className={className}>
      <SectionHeader heading="LAC in the Press" className={mobile ? "" : "pad"} />
      <ul className="reset">
        {inThePress.entries.sort((x, y) => new Date(x.date) > new Date(y.date) ? -1 : 1).map(press =>
          <li key={press.id} ><PressLink press={press} /></li>
        )}
      </ul>
    </div>}
  </div>
}

const PressLink = ({ press }) => {
  return <OutboundLink
    url={press.linkUrl}
    title={`See full article on ${press.source}`}
    className={"tab-link pad tab-link-press"}
  >
    <p>{press.title}</p>
    <div className="tab-link-press_details">
      <span>{press.source}</span>
      <span className="publish-date">{press.date}</span>
    </div>
  </OutboundLink>
}

const ModuleSection = ({ modules }) => {
  return modules.map(mod => <Module key={mod.id} module={mod} />)
}

export default Press
